import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";


import Spinner from "../app/shared/Spinner";
import FileUpload from "./fileUpload";

//import PrivateRouter from './PrivateRouter';
//import PublicRouter from './PublicRouter';

const Dashboard = lazy(() => import("./dashboard/Dashboard"));

const Candidate = lazy(() => import("./candidate/Candidate"));
const CandidateNew = lazy(() => import("./candidate/CandidateNew"));
const CandidateEdit = lazy(() => import("./candidate/CandidateEdit"));
const PublicPage = lazy(() => import("./public/PublicPage"));

const UploadFile = lazy(() => import("./fileUpload"));

const Buttons = lazy(() => import("./basic-ui/Buttons"));
const Dropdowns = lazy(() => import("./basic-ui/Dropdowns"));

const BasicElements = lazy(() => import("./form-elements/BasicElements"));

const BasicTable = lazy(() => import("./tables/BasicTable"));

const Mdi = lazy(() => import("./icons/Mdi"));

const ChartJs = lazy(() => import("./charts/ChartJs"));

const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));

const Login = lazy(() => import("./user-pages/Login"));
const Register1 = lazy(() => import("./user-pages/Register"));

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
        
          <Route exact path="/candidate" component={Candidate} />
          <Route exact path="/candidate/new" component={CandidateNew} />
          <Route exact path="/candidate/edit/:id" component={CandidateEdit} />
          <Route exact path="/upload" component={FileUpload} />

        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
