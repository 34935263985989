import { BlobServiceClient} from '@azure/storage-blob';

//const sasToken = "sv=2020-08-04&ss=bfqt&srt=sco&sp=rwdlactfx&se=2022-12-16T13:52:32Z&st=2021-12-16T05:52:32Z&spr=https&sig=HdBxvZnzipsWIshDatdrkKz7i8NHyJxleoIWkXll8hI%3D"; // process.env.storagesastoken || "sv=2020-02-10&ss=bfqt&srt=sco&sp=rwdlacuptfx&se=2021-05-28T16:49:40Z&st=2021-05-24T08:49:40Z&spr=https&sig=Ce0EinaxClvGkB71InL%2B2IEjAfd0gMsiqnNfo9wCRr8%3D"; // Fill string with your SAS token
const sasToken = process.env.REACT_APP_AZURE_SAS_TOKEN; // "sv=2020-08-04&ss=bfqt&srt=sco&sp=rwdlacupitfx&se=2022-12-23T00:08:54Z&st=2021-12-22T16:08:54Z&spr=https&sig=JBO64v43jRQN6FXrBPGl38KpHj6pPGlAKPfbk3UDaMc%3D";
const containerName = process.env.REACT_APP_AZURE_CONTAINER_NAME;
const storageAccountName =process.env.REACT_APP_AZURE_STORAGE_NAME; //process.env.storageresourcename || "storagename"; // Fill string with your Storage resource name
//https://goodjobstorageprod.blob.core.windows.net/goodjob/

function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}
// Feature flag - disable storage feature to app if not configured
export const isStorageConfigured = () => {
  return !((!storageAccountName || !sasToken));
};

// return list of blobs in container to display
const getBlobsInContainer = async (containerClient) => {
  const returnedBlobUrls = [];

  // get list of blobs in container
  // eslint-disable-next-line
  for await (const blob of containerClient.listBlobsFlat()) {
    // if image is public, just construct URL
    returnedBlobUrls.push(
      `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}`
    );
  }

  return returnedBlobUrls;
};


const createBlobInContainer = async (containerClient, file) => {
  
    var newStoredName = generateUUID();
  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(newStoredName);

  // set mimetype as determined from browser with file upload control
  const options = { blobHTTPHeaders: { blobContentType: file.type } };

  var fileNme = file.name;
  console.log(fileNme);

  // upload file
  await blobClient.uploadBrowserData(file, options);
  await blobClient.setMetadata({title : fileNme});
  
  return newStoredName;
};

const uploadFileToBlob = async (file) => {
  if (!file) return [];

  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );
  // get Container - full public read access
  const containerClient = blobService.getContainerClient(containerName);

  // upload file
  const storedName = await createBlobInContainer(containerClient, file);

  // get list of blobs in container
  return storedName;
};
// </snippet_uploadFileToBlob>

export default uploadFileToBlob;