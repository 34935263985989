import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import "./i18n";
import * as serviceWorker from './serviceWorker';
//import { GoogleAuthProvider } from './app/googleAuth'
import { Auth0Provider } from '@auth0/auth0-react';


ReactDOM.render(
  
  <BrowserRouter basename="/">
    <Auth0Provider domain={process.env.REACT_APP_AUTH0_DOMAIN} clientId={process.env.REACT_APP_AUTH0_CLIENT_ID} redirectUri={window.location.origin} organization={process.env.REACT_APP_AUTH0_ORG_ID}>
      <App />
    </Auth0Provider>
  </BrowserRouter>
  
, document.getElementById('root'));

serviceWorker.unregister();