import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { Trans } from "react-i18next";
import AuthenticationButton from '../auth0AuthenticationButton';


class Navbar extends Component {

  
  toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }
  render() {
    return (
      <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
          <a
            className="navbar-brand brand-logo-mini align-self-center d-lg-none"
            href="!#"
            onClick={(evt) => evt.preventDefault()}
          >
            <img
              src={require("../../assets/images/logo-mini.svg")}
              alt="logo"
            />
          </a>
          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          >
            <i className="mdi mdi-menu"></i>
          </button>
          <div className="page-header invisible" id="editCandidateNavTabs">
            <a href="#jobLevelsDiv">Job Levels</a>|
            <a href="#locationsDiv">Locations</a>|
            <a href="#educationDiv">Education</a>|
            <a href="#compDiv">Compensation</a>|
            <a href="#workHisDiv">Work History</a>|
            <a href="#indExpDiv">Industry Experience</a>|
            <a href="#carFieldExpDiv">Career Field Experience</a>|
            <a href="#carFieldPrefDiv">Career Field Preferences</a>|
            <a href="#indPrefDiv">Industry Preferences</a>|
            <a href="#skillsDiv">Skills</a>|<a href="#licDiv">Licenses</a>|
            <a href="#certDiv">Certifications</a>
          </div>
          
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={this.toggleOffcanvas}
          >
            <span className="mdi mdi-menu"></span>
          </button>
          <AuthenticationButton />
        </div>
      </nav>
    );
  }
}

export default Navbar;
